import React from 'react'

import { useStoreState } from '../common/storeContext'

import {
  Nav,
} from '@fluentui/react'

const AppSidebar = (props) => {
  const appState = useStoreState()

  const customRenderLink = (link) => {
    // Custom rendering for links with sub-links
    if (link.links && link.links.length > 0) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ paddingLeft: 28}}>{link.name}</span>
        </div>
      );
    }
     // Default rendering for other links
     return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{link.name}</span>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div id="react-flow-appsidebar" style={{ position: 'relative', width: appState.showNav ? '255px' : '48px', backgroundColor: '#f4f4f4', color: '#333333' }}>
        <div style={{ position: 'absolute', overflow: 'hidden', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', flexWrap: 'nowrap', flexDirection: 'column', borderRight: '1px solid #cccccc' }}>
          <div style={{ position: 'relative', flex: 1 }}>
            <div style={{ position: 'absolute', overflowY: 'auto', top: 0, right: 0, bottom: 0, left: 0 }}>
              <Nav
                selectedKey={props.selectedKey !== undefined ? '/' + props.selectedKey : '/'}
                ariaLabel="Nav basic example"
                onRenderLink={customRenderLink}
                styles={{ 
                  
                  link: { 
                    // paddingLeft: 0, 
                    selectors: { '& > span > i': { margin: '0 20px 0 15px' } } 
                  },
                  chevronButton:{
                    marginLeft: 12,
                  } ,
                  
                }}
                groups={[
                  {
                    links: appState.nav
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AppSidebar;