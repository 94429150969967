import React from 'react';

const FormRenderers = {
  'default': React.lazy(() => import('./DefaultForm')),
  'dataFlow': React.lazy(() => import('./DataFlowForm')),
  'cashBOPTransaction': React.lazy(() => import('./CBOP_transactionForm')),
  'CBOP_transaction_DAILY_SUMM': React.lazy(() => import('./CBOP_transactionForm')),
  'STATSmartDimension': React.lazy(() => import('./STATSmartDimensionForm')),
  'CCRIS_Entity_Summary_Report': React.lazy(() => import('./CCRIS_EntitySummaryReport')),
  'CCRIS_Entity_Detail_Report': React.lazy(() => import('./CCRIS_EntityDetailReport')),
  // 'statsmartFormViewer': React.lazy(() => import('./StatsmartFormView')),
  'statsmartFormViewer': React.lazy(() => import('./StatsmartFormView_IgSpread')),
  'download': React.lazy(() => import('./DownloadForm')),
}

export default FormRenderers;
