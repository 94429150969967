import React from 'react';

const DetailListRenderers = {
  'default': React.lazy(() => import('./DefaultDetailList')),
  // 'route': React.lazy(() => import('./RouteDetailList')),
  // 'validate': React.lazy(() => import('./ValidateDetailList')),
  'rule': React.lazy(() => import('./DefaultRuleList')),
  'workflow': React.lazy(() => import('./WorkflowList')),
  'submission': React.lazy(() => import('./SubmissionList')),
  'cashBOPTransaction': React.lazy(() => import('./CashBOPTransaction')),
  'statsmartForm': React.lazy(() => import('./StatsmartFormList')),
  'workflowSchedulerRun': React.lazy(() => import('./workflowSchedulerRunList')),
  'backupRestore': React.lazy(() => import('./backupRestore')),
  'adhocPIDM': React.lazy(() => import('./adhocPIDMList')),
  'api_key': React.lazy(() => import('./apiKeyList')),
  'statsmartFormViewer': React.lazy(() => import('./StatsmartFormViewList')),
  'backupModule': React.lazy(() => import('./backupRestore_V2')),
}

export default DetailListRenderers;