import React, { useRef, useEffect, useReducer, useState } from 'react';

import { useFormContext } from "react-hook-form";
import { useStoreDispatch } from '../../common/storeContext'
import { useMiddletier, useMemoCompare } from '../../common/middletier'

import { useStoreState } from '../../common/storeContext'

import {
  TextField,
  DefaultButton,
  ShimmeredDetailsList,
  SelectionMode,
  ScrollablePane,
  Sticky,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DialogType,
  SearchBox
} from '@fluentui/react'

import { useBoolean } from '@fluentui/react-hooks';

import { sortItems, filterItems } from '../../common/utils'

const moduleFieldIconProps = {
  iconName: 'ChevronDown',
  styles: { root: { fontSize: '12px' } }
}

const moduleFieldDialogProps = {

}

const _onRenderDetailsHeader = (detailsHeaderProps, defaultRender) => {
  return (<Sticky> {defaultRender(detailsHeaderProps)} </Sticky>)
}

const initialFieldState = {
  columns: [],
  items: [],
  busy: false,
  selectedKey: '',
  selectedLabel: '',
  searchTerm: '',
  filteredItems: [],
  currentKey: '',
  currentLabel: '',
  sortKey: '',
  isSortedDescending: true,
  sortedItems: [],
  showKey: false,
  attributes: [],
  module: null,
  refresh: false,
  where: '{}',
  whereNeedParse: false
}

const fieldStore = (state, action) => {
  switch (action.type) {
    case 'setBusy':
      return { ...state, busy: action.payload };
    case 'setSelectedItem':
      return { ...state, selectedKey: action.payload.key, selectedLabel: state.showKey === true ? `${action.payload.key ?? ''}: ${action.payload.label}` : action.payload.label };
    case 'setCurrentItem': {
      const _label = action.payload.key ? action.payload.label : '[Empty]'
      return { ...state, currentKey: action.payload.key, currentLabel: _label };
    }
    case 'setColumns':
      return { ...state, columns: action.payload ? [...action.payload] : [] };

    case 'setItems': {
      const _items = action.payload ? [...action.payload] : []
      const _sortedItems = state.sortKey.length > 0 ? sortItems(state.sortKey, state.isSortedDescending, _items) : [..._items]
      const _filtereditems = [...filterItems(state.searchTerm, state.columns, _sortedItems)]
      const _selectedKey = state.selectedKey ?? ''
      const _selecteditem = _items.find((itm) => { return itm.key === _selectedKey })
      const selectedLabel = state.showKey === true ? `${_selectedKey}: ${_selecteditem && _selecteditem._label ? _selecteditem._label : ''}` : `${_selecteditem && _selecteditem._label ? _selecteditem._label : ''}`

      return { ...state, items: _items, sortedItems: _sortedItems, filteredItems: _filtereditems, selectedLabel };
    }

    case 'setSearchTerm': {
      const _searchTerm = action.payload;
      const _filtereditems = [...filterItems(_searchTerm, state.columns, state.sortedItems)]

      return { ...state, searchTerm: _searchTerm, filteredItems: _filtereditems };
    }

    case 'setSort': {
      const _sortKey = action.payload.column
      const _isSortedDescending = action.payload.isSortedDescending

      // console.log('sortKey: ', _sortKey, state.sortKey, _isSortedDescending, state.isSortedDescending)
      if (_sortKey === state.sortKey && _isSortedDescending === state.isSortedDescending) {
        return state
      }

      let _columns = [...state.columns]
      const _sortedColumnIndex = _columns.findIndex((column) => {
        return column.fieldName === state.sortKey
      })

      const _newSortColumnIndex = _columns.findIndex((column) => {
        return column.fieldName === _sortKey
      })

      if (_sortedColumnIndex === _newSortColumnIndex) {
        _columns.splice(_sortedColumnIndex, 1, { ...state.columns[_sortedColumnIndex], isSortedDescending: _isSortedDescending })
      }
      else {
        if (_sortedColumnIndex !== -1) {
          _columns.splice(_sortedColumnIndex, 1, { ...state.columns[_sortedColumnIndex], isSorted: false })
        }

        if (_newSortColumnIndex !== -1) {
          _columns.splice(_newSortColumnIndex, 1, { ...state.columns[_newSortColumnIndex], isSorted: true, isSortedDescending: _isSortedDescending })
        }
      }

      // console.log('setSort:', action.type, _sortKey, _isSortedDescending, _sortedColumnIndex, _newSortColumnIndex, _columns)
      const _items = [...state.items]
      const _sortedItems = _sortKey.length > 0 ? sortItems(_sortKey, _isSortedDescending, _items) : [..._items]
      const _filtereditems = [...filterItems(state.searchTerm, state.columns, _sortedItems)]

      return { ...state, sortKey: _sortKey, isSortedDescending: _isSortedDescending, columns: _columns, sortedItems: _sortedItems, filteredItems: _filtereditems };
    }

    /*
    updateFieldState({ type: 'setModule', payload: {..._module} })
    updateFieldState({ type: 'setAttributes', payload: [..._attributes] })
    */
    case 'setModule': {
      return { ...state, module: { ...action.payload } };
    }

    case 'setAttributes': {
      return { ...state, attributes: [...action.payload] };
    }

    case 'setShowKey': {
      return { ...state, showKey: action.payload };
    }

    case 'setRefresh': {
      return { ...state, refresh: action.payload };
    }

    case 'setWhere': {
      const where = action.payload
      const replaceParams = where.match(/\$\{[0-9, a-z, ,.,_, A-Z,-]*\}/g);
      const _whereNeedParse = replaceParams && replaceParams.length > 0 ? true : false

      return { ...state, where, whereNeedParse: _whereNeedParse, refresh: _whereNeedParse === true ? false : true };
    }

    case 'setSelectedKey': {
      const _items = state.items
      const _selecteditem = _items.find((itm) => { return itm.key === action.payload ?? '' })
      const selectedLabel = state.showKey === true ? `${action.payload ?? ''}: ${_selecteditem && _selecteditem._label ? _selecteditem._label : ''}` : `${_selecteditem && _selecteditem._label ? _selecteditem._label : ''}`

      return { ...state, selectedKey: action.payload, selectedLabel };
    }

    default:
      throw new Error();
  }
}

const ModuleField = (props) => {
  const appState = useStoreState()
  const appDispatch = useStoreDispatch()

  const { getValues } = useFormContext()

  const { findAll } = useMiddletier()
  const [fieldState, updateFieldState] = useReducer(fieldStore, initialFieldState)
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)

  const moduleFieldDetailListRef = useRef()
  const [styles, setStyles] = useState({})

  const _props = useMemoCompare(
    JSON.stringify({
      columns: props.columns ? [...props.columns] : null,
      items: props.items ? [...props.items] : null,
      sort: props.sort,
      reference: props.reference,
      where: props.where ?? '{}',
      attributes: props.attributes ?? [],
      showKey: props.showKey ?? false,
      setKey: props.setKey ?? null,
    }), (prev, next) => {
      return prev && prev === next;
    }
  )

  const onSelect = () => {
    if (moduleFieldDetailListRef && moduleFieldDetailListRef.current) {
      const _selection = moduleFieldDetailListRef.current._selection.getSelection()
      if (_selection.length > 0) {
        //console.log('moduleFieldDetailListRef: ', moduleFieldDetailListRef.current._selection.getSelection())
        if (props.onChange) {
          props.onChange(_selection[0].key, { ..._selection[0] })
        }
        toggleHideDialog()
      }
    }
  }

  const _onSearchChange = (e, newValue) => {
    updateFieldState({ type: 'setSearchTerm', payload: newValue })
  }

  const _onShowDialog = () => {
    if (fieldState.whereNeedParse === true) {
      updateFieldState({ type: 'setRefresh', payload: true })
    }

    updateFieldState({ type: 'setCurrentItem', payload: { key: fieldState.selectedKey, label: fieldState.selectedLabel } })
    toggleHideDialog()
  }

  const _onSetEmpty = () => {
    toggleHideDialog()
    if (props.onChange) {
      props.onChange(null, { key: null })
    }
  }

  const _onDidUpdate = () => {
    if (fieldState.selectedKey && fieldState.selectedKey.length > 0 && moduleFieldDetailListRef.current._selection.getSelection().length === 0) {
      //console.log('_onDidUpdate.moduleFieldDetailListRef: ', moduleFieldDetailListRef.current._selection.getSelection())
      moduleFieldDetailListRef.current._selection.setKeySelected(fieldState.selectedKey, true, true)
    }
  }

  const _onActiveItemChanged = (item, index, evt) => {
    //console.log('_onActiveItemChanged: ', item.key, item._label)
    updateFieldState({ type: 'setCurrentItem', payload: { key: item.key, label: item._label } })
  }

  const _onColumnClick = (evt, item) => {
    // console.log('_onColumnClick: ', item)
    updateFieldState({ type: 'setSort', payload: { column: item.fieldName, isSortedDescending: !item.isSortedDescending } })
  }

  useEffect(() => {
    const { columns, items, sort, reference, attributes, showKey, where, setKey } = JSON.parse(_props)

    updateFieldState({ type: 'setShowKey', payload: showKey })

    if (columns && typeof (columns) === 'object' && items && typeof (items) === 'object') {
      let _sort = sort ?? '_label'

      const _columns = columns.map((column) => {
        if (column.key === _sort)
          return { key: column.key, name: column.text, fieldName: column.key, minWidth: 100, maxWidth: 200, isResizable: true, isSorted: true, isSortedDescending: true, onColumnClick: _onColumnClick }
        else
          return { key: column.key, name: column.text, fieldName: column.key, minWidth: 100, maxWidth: 200, isResizable: true, onColumnClick: _onColumnClick }
      })

      updateFieldState({ type: 'setSort', payload: { column: _sort, isSortedDescending: true } })
      updateFieldState({ type: 'setColumns', payload: [..._columns] })
      updateFieldState({ type: 'setItems', payload: [...items] })
    }
    else {
      const _module = appState.modules.find((module) => { return module.code === reference })
      if (_module) {
        let _attributes = [[setKey ?? _module.isId.code, 'key'], [_module.isId._label, '_label']]
        const _picklistView = _module._views.find((view) => { return view.type === 'picklist' })
        let _properties = JSON.parse(_picklistView.properties)
        // console.log('>>>>>>>>', '_properties: ', _properties.length, _properties)
        if (_properties.length && _properties.length > 0) {
          _properties = { ..._properties[0] }
        }
        // console.log('<<<<<<<', '_properties: ', _properties)
        const _columns = _properties.columns.reduce((result, item) => {
          const _property = _module._properties.find((property) => { return property.code === item })
          if (_property) {
            _attributes.push(_property.key)
            const _fieldName = (_property.type === 'listgroup' || _property.type === 'module') ? _property._label : item

            if (_property.type === 'listgroup' || _property.type === 'module') {
              _attributes.push(_property._label)
            }

            if (_property.type === 'money') {
              result.push({
                key: item, name: _property.name, fieldName: _fieldName, minWidth: 100, maxWidth: 200, isResizable: true, onColumnClick: _onColumnClick, onRender: (item, index, column) => {
                  const value = parseFloat(item[column.key]).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  return <div style={{ textAlign: 'end' }}>{value}</div>//item[column.key]
                }
              })
            }
            else {
              result.push({ key: item, name: _property.name, fieldName: _fieldName, minWidth: 100, maxWidth: 200, isResizable: true, onColumnClick: _onColumnClick })
            }
            return result
          }
          else {
            console.error('item not found: ', item, _module._properties)
            return result
          }
        }, [])

        attributes.forEach((item) => {
          const _property = _module._properties.find((property) => { return property.code === item })
          if (_property) {
            _attributes.push(_property.key)
            if (_property.type === 'listgroup' || _property.type === 'module') {
              _attributes.push(_property._label)
            }
          }
          else {
            console.error('item not found: ', item, _module._properties)
          }
        })

        updateFieldState({ type: 'setColumns', payload: _columns })

        if (sort) {
          // console.log('sort: ', sort)
          updateFieldState({ type: 'setSort', payload: { column: sort, isSortedDescending: true } })
        }
        else {
          // console.log('sort._module.isId._label: ', _module.isId._label)
          updateFieldState({ type: 'setSort', payload: { column: _module.isId._label, isSortedDescending: true } })
        }

        updateFieldState({ type: 'setModule', payload: { ..._module } })
        updateFieldState({ type: 'setAttributes', payload: [..._attributes] })
        updateFieldState({ type: 'setWhere', payload: typeof (where) === 'object' ? JSON.stringify(where) : where })
      }
    }

  }, [appState.modules, _props])

  useEffect(() => {
    if (fieldState.refresh === true) {
      const _module = fieldState.module
      updateFieldState({ type: 'setBusy', payload: true })

      let whereValue = fieldState.where
      if (fieldState.whereNeedParse === true) {
        const _values = { ...getValues() }
        const replaceParams = whereValue.match(/\$\{[0-9, a-z, ,.,_, A-Z,-]*\}/g);
        replaceParams.forEach((item) => {
          whereValue = whereValue.replace(item, _values[item.substring(2, item.length - 1)] ?? item)
        })
      }

      updateFieldState({ type: 'setRefresh', payload: false })
      findAll({ method: _module.plural, attributes: fieldState.attributes, where: JSON.parse(whereValue.length === 0 ? '{}' : whereValue) })
        .then(({ data }) => {
          updateFieldState({ type: 'setBusy', payload: false })
          updateFieldState({ type: 'setItems', payload: data[_module.plural] })
        })
        .catch((error) => {
          error.graphQLErrors.forEach((_error) => {
            if (_error.extensions.code === 'UNAUTHENTICATED') {
              appDispatch({ type: 'setErrorMessage', payload: _error.message })
            }
          })
        })
    }
  }, [appDispatch, findAll, fieldState.refresh, fieldState.attributes, fieldState.module, fieldState.where, fieldState.whereNeedParse, getValues])

  useEffect(() => {
    updateFieldState({ type: 'setSelectedKey', payload: props.value })
    if (fieldState.whereNeedParse === true) {
      updateFieldState({ type: 'setRefresh', payload: true })
    }
  }, [props.value, fieldState.whereNeedParse, getValues])

  useEffect(() => {
    if (props.styles) {
      const styles = { ...props.styles, field: { cursor: 'pointer' } }
      setStyles(styles)
    }
    else {
      setStyles({ field: { cursor: 'pointer' } })
    }
  }, [props.styles])

  return (
    <React.Fragment>
      <TextField {...props} autoComplete="off" styles={styles} value={fieldState.selectedLabel ?? ''} readOnly iconProps={moduleFieldIconProps} onClick={_onShowDialog} />
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={{ type: DialogType.normal, title: props.label ? props.label + ': ' + fieldState.currentLabel : fieldState.currentLabel, styles: { content: { width: (fieldState.columns.length * 130 + 120) > 600 ? (fieldState.columns.length * 130 + 120) + 'px' : '600px' } } }}
        modalProps={moduleFieldDialogProps}
        maxWidth={(fieldState.columns.length * 130 + 120) > 600 ? (fieldState.columns.length * 130 + 120) : 600}
      >
        <div style={{ height: '332px', width: '100%' }}>
          <div style={{ position: 'relative', height: '32px', margin: '2px 0px', width: '100%' }}>
            <div style={{ position: 'absolute', overflow: 'hidden', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', flexWrap: 'nowrap', flexDirection: 'column' }}>
              <SearchBox autoComplete="off" placeholder="Search" value={fieldState.searchTerm} onChange={_onSearchChange} />
            </div>
          </div>
          <div style={{ position: 'relative', height: '300px', margin: '2px 0px', width: '100%' }}>
            <div style={{ position: 'absolute', overflow: 'hidden', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
              <ScrollablePane>
                <ShimmeredDetailsList
                  componentRef={moduleFieldDetailListRef}
                  items={fieldState.filteredItems}
                  columns={fieldState.columns}
                  selectionMode={SelectionMode.single}
                  onRenderDetailsHeader={_onRenderDetailsHeader}
                  enableShimmer={fieldState.busy}
                  ariaLabelForShimmer="Content is being fetched"
                  ariaLabelForGrid="Item details"
                  onDidUpdate={_onDidUpdate}
                  onActiveItemChanged={_onActiveItemChanged}
                />
              </ScrollablePane>
            </div>
          </div>
        </div>
        <DialogFooter>
          <PrimaryButton onClick={onSelect} text="Select" />
          <DefaultButton styles={{ root: { display: props.required ? 'none' : 'inline-block' } }} onClick={_onSetEmpty} text="Set Empty" />
          <DefaultButton onClick={toggleHideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </React.Fragment>
  )
}

export default ModuleField;