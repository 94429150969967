import { format } from 'date-fns'

const capitalize = (word) => {
  return `${word.slice(0, 1).toUpperCase()}${word.slice(1)}`
}

const camelize = (text, separator = ' ') => {
  const words = text.replace(/[&\\/\\#,+()$~%.'":*?<>{}\-[\]=|;`!@^]/g, '').split(separator)
  const result = [words[0]]
  words.slice(1).forEach((word) => result.push(capitalize(word)))
  const retVal = result.join('')
  return `${retVal.slice(0, 1).toLowerCase()}${retVal.slice(1)}`
}

const getGraphQLAttributeType = (type = '') => {
  switch (type) {
    case 'string':
    case 'listgroup':
    case 'json':
    case 'jsonlist':
    case 'text':
      return 'String';

    case 'integer':
      return 'Int';

    case 'float':
      return 'Float';

    case 'money':
      return 'Float';

    case 'boolean':
      return 'Boolean';

    case 'datetime':
      return 'DateTime';

    case 'date':
      return 'Date';

    case 'uuid':
      return 'ID';

    case 'seqnum':
      return 'Int';
    default:
      return 'String';
  }
}

const getObjectValues = (object, keys, values) => {
  var _object = { ...object }

  if (typeof (keys) === 'object' && keys.length !== undefined) {
    keys.forEach(item => {
      _object[item.key] = values[item.value]
    })
  }
  else {
    _object[keys] = values
  }

  return _object
}

const generateSQLCondition = (field, condition, value, type, mode = 'string', prefix = '') => {
  let _type = type

  if (type === 'text' || type === 'module' || type === 'listgroup' || type === 'json' || type === 'jsonlist') {
    _type = 'string'
  }

  // console.log('generateSQLCondition: ', field, condition, value, _type)

  if (mode === 'string') {
    return _generateSQLConditionForString(field, condition, value, _type, prefix)
  }
  else {
    return _generateSQLConditionForJSON(field, condition, value, _type, prefix)
  }
}

const _generateSQLConditionForJSON = (field, condition, value, type, prefix) => {
  /*
const operatorsAliases = {
    $eq: Op.eq,
    $ne: Op.ne,
    $gte: Op.gte,
    $gt: Op.gt,
    $lte: Op.lte,
    $lt: Op.lt,
    $not: Op.not,
    $in: Op.in,
    $notIn: Op.notIn,
    $is: Op.is,
    $like: Op.like,
    $notLike: Op.notLike,
    $iLike: Op.like,
    $notILike: Op.notLike,
    $regexp: Op.regexp,
    $notRegexp: Op.notRegexp,
    $iRegexp: Op.iRegexp,
    $notIRegexp: Op.notIRegexp,
    $between: Op.between,
    $notBetween: Op.notBetween,
    $overlap: Op.overlap,
    $contains: Op.contains,
    $contained: Op.contained,
    $adjacent: Op.adjacent,
    $strictLeft: Op.strictLeft,
    $strictRight: Op.strictRight,
    $noExtendRight: Op.noExtendRight,
    $noExtendLeft: Op.noExtendLeft,
    $and: Op.and,
    $or: Op.or,
    $any: Op.any,
    $all: Op.all,
    $values: Op.values,
    $col: Op.col
};
*/
  var _value = value

  const _field = prefix.length > 0 && field.indexOf('.') === -1 ? `${prefix}.${field}` : field

  if (type === 'datetime') {
    
    if (value && value !== null) {
      const startTime = `${format(value, 'yyyy-MM-dd')} 00:00:00.000+08`
      const endTime = `${format(value, 'yyyy-MM-dd')} 23:59:59.999+08`

      if (condition === 'isEqualTo') {
        return `{"${_field}": {"$between": ["${startTime}", "${endTime}"]}}`
      }
      else if (condition === 'isNotEqualTo') {
        return `{"${_field}": {"$notBetween": ["${startTime}", "${endTime}"]}}`
      }
      else if (condition === 'lessThan') {
        return `{"${_field}": {"$lt": "${startTime}"}}`
      }
      else if (condition === 'lessThanOrEqualTo') {
        return `{"${_field}": {"$lte": "${endTime}"}}`
      }
      else if (condition === 'greaterThan') {
        return `{"${_field}": {"$gt": "${endTime}"}}`
      }
      else if (condition === 'greaterThanOrEqualTo') {
        return `{"${_field}": {"$gte": "${startTime}"}}`
      }
    }
    else if (condition === 'isNull') {
      return `{"${_field}": {"$isNull": ""}}`
    }
    else if (condition === 'isNotNull') {
      return `{"${_field}": {"$isNotNull": ""}}`
    }
    
    return ``
  }
  else if (typeof (value) === 'object' && value.length > 0) {
    if (type === 'string') {
      _value = `["${value.join('","')}"]`
    }
    else {
      _value = `[${value.join(',')}]`
    }
  }
  else if (type === 'date' && value instanceof Date) {
    _value = '"' + value.getFullYear() + (value.getMonth() + 1).toString().padStart(2, '0') + (value.getDate()).toString().padStart(2, '0') + '"'
  }
  else if (type === 'string') {
    _value = '"' + value + '"'
  }

  switch (condition) {
    case 'isNull': return `{"${_field}": {"$isNull": ""}}`
    case 'isNotNull': return `{"${_field}": {"$isNotNull": ""}}`
    case 'isEqualTo': return `{"${_field}": ${_value}}` //type === 'string' ? `UPPER(${field}) = '${value.toUpperCase()}'` : `${field} = ${_value}`
    case 'isNotEqualTo': return `{"${_field}": {"$ne": ${_value}}}` //ttype === 'string' ? `UPPER(${field}) != '${value.toUpperCase()}'` : `${field} != ${_value}`//return `UPPER(${field} != ${_type === 'string' ? '\'' : ''}${value}${_type === 'string' ? '\'' : ''}`
    case 'contains': return `{"${_field}": {"$like": "%${value}%"}}` //`UPPER(${field}) LIKE '%${value.toUpperCase()}%'`
    case 'notContains': return `{"${_field}": {"$notLike": "%${value}%"}}` //return `UPPER(${field}) NOT LIKE '%${value.toUpperCase()}%'`
    case 'beginWith': return `{"${_field}": {"$like": "${value}%"}}` //return `UPPER(${field}) LIKE '${value.toUpperCase()}%'`
    case 'endWith': return `{"${_field}": {"$like": "%${value}"}}` //return `UPPER(${field}) LIKE '%${value.toUpperCase()}'`
    case 'greaterThan': return `{"${_field}": {"$gt": ${_value}}}` //return `${field} > ${value}`
    case 'greaterThanOrEqualTo': return `{"${_field}": {"$gte": ${_value}}}` //`${field} >= ${value}`
    case 'lessThan': return `{"${_field}": {"$lt": ${_value}}}` //`${field} < ${value}`
    case 'lessThanOrEqualTo': return `{"${_field}": {"$lte": ${_value}}}` //`${field} <= ${value}`
    case 'isTrue': return `{"${_field}": {"$is": true}}` //`${field} = true`
    case 'isFalse': return `{"${_field}": {"$is": false}}` //`${field} = false`
    case 'in': return typeof (value) === 'object' && _value.length > 0 ? `{"${_field}": {"$in": ${_value}}}` : '' //`${field} IN (${_value})` : ''
    case 'notIn': return typeof (value) === 'object' && _value.length > 0 ? `{"${_field}": {"$notIn": ${_value}}}` : '' //`${field} NOT IN (${_value})` : ''
    default: return ''
  }
}

const _generateSQLConditionForString = (field, condition, value, type, prefix) => {
  var _value = value

  if (typeof (value) === 'object' && value.length > 0) {
    if (type === 'string') {
      _value = `'${value.join('\',\'')}'`
    }
    else {
      _value = `${value.join(',')}`
    }
  }
  else if (type === 'date' && value instanceof Date) {
    _value = '\'' + value.getFullYear() + (value.getMonth() + 1).toString().padStart(2, '0') + (value.getDate()).toString().padStart(2, '0') + '\''
  }

  const _field = prefix.length > 0 && field.indexOf('.') === -1 ? `${prefix}.${field}` : field

  switch (condition) {
    case 'isNull': return `${_field} IS NULL`
    case 'isNotNull': return `${_field} IS NOT NULL`
    case 'isEqualTo': return type === 'string' ? `UPPER(${_field}) = '${value.toUpperCase()}'` : `${_field} = ${_value}`
    case 'isNotEqualTo': return type === 'string' ? `UPPER(${_field}) != '${value.toUpperCase()}'` : `${_field} != ${_value}`//return `UPPER(${field} != ${_type === 'string' ? '\'' : ''}${value}${_type === 'string' ? '\'' : ''}`
    case 'contains': return `UPPER(${_field}) LIKE '%${value.toUpperCase()}%'`
    case 'notContains': return `UPPER(${_field}) NOT LIKE '%${value.toUpperCase()}%'`
    case 'beginWith': return `UPPER(${_field}) LIKE '${value.toUpperCase()}%'`
    case 'endWith': return `UPPER(${_field}) LIKE '%${value.toUpperCase()}'`
    case 'greaterThan': return `${_field} > ${value}`
    case 'greaterThanOrEqualTo': return `${_field} >= ${value}`
    case 'lessThan': return `${_field} < ${value}`
    case 'lessThanOrEqualTo': return `${_field} <= ${value}`
    case 'isTrue': return `${_field} = true`
    case 'isFalse': return `${_field} = false`
    case 'in': return typeof (value) === 'object' && _value.length > 0 ? `${_field} IN (${_value})` : ''
    case 'notIn': return typeof (value) === 'object' && _value.length > 0 ? `${_field} NOT IN (${_value})` : ''
    default: return ''
  }
}

const toGraphQLValue = ({ value, type, required = true }) => {
  if (typeof (value) === 'string' && value.trim() === '' && required === false) {
    return null
  }

  if (type === 'String') {
    return value
  }
  else if (type === 'Int') {
    return parseInt(value)
  }
  else if (type === 'Float') {
    return parseFloat(value)
  }
  else if (type === 'Date') {
    if (typeof (value) === 'object' && value instanceof Date)
      return `${value.getFullYear()}-${('' + (value.getMonth() + 1)).padStart(2, '0')}-${('' + value.getDate()).padStart(2, '0')}`
    else
      return value
  }
  else if (type === 'ID') {
    if (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(value))
      return value
    else
      return null
  }
  else {
    return value
  }
}

const toDataValue = ({ value, type }) => {
  if (type === 'Date') {
    if (value === '')
      return format(new Date(), 'yyyy-MM-dd')
    return format(new Date(value), 'yyyy-MM-dd')
  }
  else if (type === 'DateTime') {
    if (value === '')
      return new Date()
    return new Date(value)
  }
  else if (type === 'Boolean') {
    if (typeof (value) === 'string')
      return value.toLowerCase() === 'true' ? true : false
    return value
  }
  else {
    return value
  }
}

const parseValues = ({ data = {}, properties = [] }) => {
  if (properties.length === 0) {
    return data;
  }

  let retVal = {}
  Object.keys(data).forEach((key) => {
    const property = properties.find((_property) => { return _property.key === key })
    if (property && data[key] !== null) {
      retVal[key] = toDataValue({ value: data[key], type: property.graphqlType })
    }
    else {
      retVal[key] = data[key]
    }
  })

  return retVal
}

const filterItems = (searchTerm = '', columns = [], items = []) => {
  const _searchTerm = searchTerm.trim().toUpperCase()

  if (searchTerm.length === 0) {
    return items
  }

  const _columns = columns.map(column => {
    return column.fieldName
  })

  return items.reduce((result, item) => {
    let bFound = false
    for (let i = 0; i < _columns.length; i++) {
      const _item = item[_columns[i]]
      if (typeof (_item) !== 'undefined' && _item !== null && _item.toString().toUpperCase().indexOf(_searchTerm) !== -1) {
        bFound = true
        break;
      }
    }

    if (bFound) {
      result.push({ ...item });
    }

    return result;
  }, []);
}

const sortItems = (sortKey, isSortedDescending, items) => {
  if (sortKey.length === 0) {
    return [...items]
  }

  return items.sort((a, b) => {
    let _key1 = isSortedDescending ? a[sortKey] : b[sortKey]
    let _key2 = isSortedDescending ? b[sortKey] : a[sortKey]

    if (_key1 === null) {
      _key1 = ''
    }

    if (_key2 === null) {
      _key2 = ''
    }

    if (_key1 > _key2) {
      return 1
    }
    else if (_key1 < _key2) {
      return -1
    }

    return 0
  })
}

const sortItemsByGroup = ({ sortKey = '', isSortedDescending, items = [], groupKey = '' }) => {
  if (items.length === 0) {
    return { items: [], groups: [] }
  }

  if (groupKey.length === 0) {
    if (sortKey.length === 0) {
      return { items: [...items], groups: [] }
    }
    else {
      return {
        items: items.sort((a, b) => {
          let _key1 = isSortedDescending ? a[sortKey] : b[sortKey]
          let _key2 = isSortedDescending ? b[sortKey] : a[sortKey]

          if (_key1 === null) {
            _key1 = ''
          }

          if (_key2 === null) {
            _key2 = ''
          }

          if (_key1 > _key2) {
            return 1
          }
          else if (_key1 < _key2) {
            return -1
          }

          return 0
        }),
        groups: []
      }
    }
  }
  else {
    /*
    background: 'rgb(223, 246, 221)',
    color: 'rgb(50, 49, 48)',
    ':focus': {
      background: 'rgb(223, 246, 221)',
      color: 'rgb(50, 49, 48)',
    },
  },
  "tag-warning": {
    background: 'rgb(255, 244, 206)',
    color: 'rgb(50, 49, 48)',
    ':focus': {
      background: 'rgb(255, 244, 206)',
      color: 'rgb(50, 49, 48)',
    },
  },
  "tag-error": {
    background: 'rgb(253, 231, 233)',
    */
    const colors = {2: 'rgb(168, 0, 0)', 1: 'rgb(252, 225, 0)', 0: 'rgb(16, 124, 16)'}
    let _groups = [], _items = []
    if (groupKey === 'tags') {
      let _tagGroups = {}
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        if (typeof (item[groupKey]) === 'object' && item[groupKey].length > 0) {
          for (let j = 0; j < item[groupKey].length; j++) {
            const key = item[groupKey][j]
            if (typeof (_tagGroups[key.type]) === 'undefined') {
              _tagGroups[key.type] = []
            }

            const index = _tagGroups[key.type].findIndex((itm) => { return itm.name === key.text })
            if (index === -1) {
              _tagGroups[key.type].push({ key: key.key, name: key.text, type: key.type, items: [{ ...item, color: colors[key.type] }] })
            }
            else {
              _tagGroups[key.type][index].items.push({ ...item, color: colors[key.type] })
            }
          }
        }
        else {
          if (typeof (_tagGroups[-1]) === 'undefined') {
            _tagGroups[-1] = [{ key: '-1', name: "No tag", type: '-1', items: [] }]
          }
          _tagGroups[-1][0].items.push({ ...item, color: null })
        }
      }

      const keys = Object.keys(_tagGroups)
      for (let i = 0; i < keys.length; i++) {
        const group = _tagGroups[keys[i]]
        for (let j = 0; j < group.length; j++) {
          _tagGroups[keys[i]][j].items = group[j].items.sort((a, b) => {
            let _key1 = isSortedDescending ? a[sortKey] : b[sortKey]
            let _key2 = isSortedDescending ? b[sortKey] : a[sortKey]

            if (_key1 === null) {
              _key1 = ''
            }

            if (_key2 === null) {
              _key2 = ''
            }

            if (_key1 > _key2) {
              return 1
            }
            else if (_key1 < _key2) {
              return -1
            }

            return 0
          })
        }

        _tagGroups[keys[i]] = _tagGroups[keys[i]].sort((a, b) => {
          let _key1 = a['name']
          let _key2 = b['name']

          if (_key1 === null) {
            _key1 = ''
          }

          if (_key2 === null) {
            _key2 = ''
          }

          if (_key1 > _key2) {
            return 1
          }
          else if (_key1 < _key2) {
            return -1
          }

          return 0
        })
      }

      [2, 1, 0, -1].forEach(key => {
        if (typeof (_tagGroups[key]) !== 'undefined') {
          _tagGroups[key].forEach((group) => {
            _groups.push({ key: group.key, name: group.name, count: group.items.length, startIndex: _items.length })
            _items.push(...group.items)
          })
        }
      })
    }

    return { items: _items, groups: _groups }
  }
}

const getSelectableColumns = (columns, availableColumns) => {
  let _selectableColumns = []

  columns.forEach((column) => {
    const _property = availableColumns.find((_column) => {
      return _column.key === column.key
    })
    if (_property) {
      _selectableColumns.push({ ..._property, checked: true, draggable: _property.isLabel ? false : true })
    }
  })

  availableColumns.forEach((column) => {
    const _index = columns.findIndex((_column) => { return _column.key === column.key })
    if (_index === -1) {
      _selectableColumns.push({ ...column, checked: false, draggable: false })
    }
  })

  return _selectableColumns
}

const mergeFormPropertiesWithAttributes = ({ formProperties, attributes }) => {
  let _formProperties = []
  let _columns = []
  if (formProperties) {
    for (let i = 0; i < formProperties.length; i++) {
      const _property = formProperties[i]
      if (_property.type === 'column') {
        const _attribute = attributes.find((itm) => { return itm.code === _property.code })
        if (_attribute) {
          _columns.splice(_columns.length, 0, { ..._attribute })
          _formProperties.splice(_formProperties.length, 0, { ..._property, code: _attribute.key, attribute: { ..._attribute } })
        }
      }
      else {
        const { properties, columns } = mergeFormPropertiesWithAttributes({ formProperties: _property.properties, attributes })
        _formProperties.splice(_formProperties.length, 0, { ..._property, properties })
        _columns.splice(_columns.length, 0, ...columns)
      }
    }
  }

  return { properties: _formProperties, columns: _columns }
}

export { capitalize, camelize, getGraphQLAttributeType, getObjectValues, generateSQLCondition, toGraphQLValue, toDataValue, parseValues, filterItems, sortItems, getSelectableColumns, sortItemsByGroup, mergeFormPropertiesWithAttributes }